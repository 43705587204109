import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"
import {
  Box,
  UnstyledBoxes,
  BoxHeader,
  BoxPoints,
  BoxLogo,
} from "../../case-study/common/boxes"

import { Button } from "../atoms/Button"
import { SecondaryTitle } from "../common/text"

const BoxesContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 50px 0 0 0;

  @media ${minD.tablet} {
    top: var(--container-top, -120px);
  }

  @media ${minD.laptop} {
    margin: 0 0 0 var(--container-left-margin-laptop, 70px);
  }

  @media ${minD.laptopL} {
    margin: 0 0 0 var(--container-left-margin-desktop, 120px);
  }

  &::before {
    @media ${minD.laptop} {
      content: var(--line-content, "");
    }

    display: block;
    position: absolute;

    width: 1px;
    height: 100%;

    left: 220px;
    top: -100%;
    z-index: -1;

    background-color: var(--border-green);
  }
`

const CustomBoxes = styled(UnstyledBoxes)`
  @media ${maxD.tablet} {
    margin-bottom: 30px;
  }
`

const StyledBox = styled(Box)`
  padding: 30px;
  position: relative;
  width: 50%;

  background-color: var(--box-background-color, --light-gray);

  &:nth-child(even) {
    @media ${minD.tablet} {
      top: 120px;
    }
  }

  &:nth-child(2),
  &:nth-child(6) {
    @media ${minD.tablet} {
      background-color: var(--faded-green);
      border-color: var(--faded-green);
    }
  }

  @media ${maxD.tablet} {
    --box-padding-vertical: 30px;
    --box-padding-horizontal: 20px;

    width: 100%;
  }
`

const BoxText = styled.p`
  color: var(--paragraph-gray);
  font-weight: 400;
`

const ButtonContainer = styled.div`
  @media ${minD.tablet} {
    position: absolute;

    left: 0;
    bottom: -120px;
  }
`

const MasonryBoxes = ({ boxes, button, boxStyles, boxesContainerStyles }) => {
  return (
    <BoxesContainer style={boxesContainerStyles}>
      <CustomBoxes>
        {boxes &&
          boxes.map((box, i) => (
            <StyledBox key={i} style={boxStyles}>
              {box.header ? (
                <SecondaryTitle>{box.header}</SecondaryTitle>
              ) : (
                <BoxHeader>
                  <BoxPoints>{box.csEffectsNumber}</BoxPoints>
                  {box.csEffectsLogo && (
                    <BoxLogo
                      dangerouslySetInnerHTML={{ __html: box.csEffectsLogo }}
                    />
                  )}
                </BoxHeader>
              )}
              <BoxText>{box.description}</BoxText>
            </StyledBox>
          ))}
      </CustomBoxes>
      {button && button.url && (
        <ButtonContainer>
          <Button to={button.url} target={button.target} theme="green">
            {button.title}
          </Button>
        </ButtonContainer>
      )}
    </BoxesContainer>
  )
}

export default MasonryBoxes
